import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Aiml = () => {

  return (
    <>
      <div className="change-culture1 ptb-50 bg-f7fafd">
        <div className="container">
          <div className="row align-items-center pt-80">
            <div className="col-lg-6 col-md-12">
              <div className="about-image" style={{ paddingRight: "10px" }}>
                <StaticImage
                  src="../../assets/images/ai-ml.png"
                  alt="Why join Atyeti?"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="change-culture-content">
                <h3>AI&ML @ Atyeti</h3>
                <div className="section-title">
                  <div className="bar"></div>
                  <p>
                    Leverage dynamic AI capabilities to efficiently transform
                    and modernize your data infrastructure and application
                    ecosystem. Our expertise lies in crafting, managing, and
                    fine-tuning AI-driven solutions aimed at automating
                    development and analytical workflows, consolidating
                    knowledge and reasoning systems, and providing frictionless,
                    on-demand access to your organization's intelligence.
                  </p>
                  {/* <img src={dayinlife} /> */}
                </div>
              </div>
            </div>
            <div className="services-area-two pt-80 pb-50">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                  <div className="aiml-box">
                      <h3>Secure, Private LLM Development</h3>
                      <ul>
                        <li>
                          Enable bespoke Large Language Models (LLM), or
                          leverage opensource models, within the Enterprise.
                        </li>
                        <li>
                          Preserve data sovereignty and ensuring compliance with
                          data custody and jurisdiction regulations.
                        </li>
                        <li>
                          Implement best practices in data encryption and
                          privacy, compliance, and emerging AI regulations.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="aiml-box">
                      <h3>Automation and Modernization</h3>
                      <ul>
                        <li>
                          Reduce size of analytics and development teams while
                          bringing cycle time from quarters to minutes.
                        </li>
                        <li>
                          Transform static resources into dynamic, accessible
                          intelligence facilitating rapid, ad-hoc insights.
                        </li>
                        <li>
                          Unify diverse data ecosystems to offer a centralized
                          and diversified intelligence framework.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="aiml-box">
                      <h3>Conversational AI and Agents</h3>
                      <ul>
                        <li>
                          Digital agents that offer instantaneous, context-aware
                          responses, significantly enhancing user engagement.
                        </li>
                        <li>
                          Natural Language Processing (NLP) capabilities, that
                          evolve, learn, and adapt to user interactions.
                        </li>
                        <li>
                          Build conversational interfaces that seamlessly
                          integrate with existing systems and processes.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Aiml
